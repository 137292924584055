import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import Page from '../templates/Page'
import Seo from '../components/seo'
import {
  H2,
  Hero,
  Text,
  Container,
  TwoColumnGrid,
  BigSmallColumn,
  Button,
} from '../utils/styles'
import { colors, space } from '../utils/theme'
import { useInViewportAnimation } from '../utils/hooks'

const LeichtbauPage = () => {
  useInViewportAnimation()

  return (
    <Page useContainer={false}>
      <Seo title="Kontakt" />

      <Container>
        <Hero>Leichtbau - Saugsystem</Hero>

        <TwoColumnGrid>
          <div>
            <H2>Vorsprung im Transport</H2>
            <Text>
              Als Fertigungsexperte sind Sie immer auf der Suche, Ihre
              Produktion zu optimieren. Das Herga Leichtbau-Saugsystem
              verschafft Ihnen beim Transport von leichten und mittelschweren
              Teilen einen großen Vorteil gegenüber den Standardlösungen. Dabei
              ist es genau so robust wie die üblichen Saugsysteme aus Aluminium
              und Stahl, aber wesentlich leichter. Das flexible Polyamid geht
              auch bei einem Crash nicht zu Bruch und nimmt wieder von alleine
              seine Ausgansposition an bzw. lässt es sich im Notfall wieder
              leicht zurückbiegen. <br />
              <br />
              Der Kunststoff ist auch wesentlich günstiger im Vergleich zu den
              Versionen aus Metall. Lösungen für Spezialfälle können Sie sich
              entweder direkt selbst zusammenbauen oder bei unserem
              Ingenieurbüro in Auftrag geben.
              <br />
              <br /> Die Flexibilität hört nicht bei der Materie auf – mit dem
              Modul-System können Sie eine große Anzahl an Anwendungsgebieten
              abdecken. Vom Individuellen Saugeranschluss nach Maß bis hin zu
              Balgsauggreifern und Flachsaugern. Den passenden Greifer bauen Sie
              persönlich in Windeseile zusammen. Es werden nur drei Werkzeuge
              dafür benötigt. Wenige Grundteile eröffnen Ihnen bereits ein enorm
              breites Anwendungsspektrum.
              <br />
              <br />
              Das Herga Leichtbau-Saugsystem (rechts) ist circa 65 % leichter
              als die üblichen Systeme – und das bei gleicher Stabilität.
            </Text>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <div data-inviewport="fade-in-right">
              <StaticImage
                src="../images/leichtbau_vorsprung_im_transport.png"
                layout="constrained"
                alt="Lightweight System"
                loading="lazy"
                objectFit="contain"
              />
            </div>
            <Text></Text>
          </div>
        </TwoColumnGrid>
      </Container>
      
      <div
        style={{
          marginTop: space[20],
          backgroundColor: colors.pearlBush,
          padding: space[6],
        }}
      >
        <Container>
          <TwoColumnGrid gap={space[6]}>
            <div>
              <StaticImage src="../images/IMG_7253JPG_0001_Hintergrundjpg_0001_Kurven-1-768x314-b810684a.jpeg" />
              <Text>Standardlösung aus Aluminium oder Stahl</Text>
            </div>
            <div>
              <StaticImage src="../images/IMG_7253JPG_0001_Hintergrundjpg_0000_Kurven-1-Kopie-768x314-41b94377.jpeg" />
              <Text>Herga Leichtbau-Saugsystem aus leichtem Kunststoff</Text>
            </div>
          </TwoColumnGrid>
        </Container>
      </div>

      <Container>
        <TwoColumnGrid style={{ marginTop: space[20] }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              border: colors.grey,
            }}
          >
            <div data-inviewport="fade-in-left">
              <StaticImage
                src="../images/leichtbau_vorteile_auf_einem_blick.png"
                layout="constrained"
                objectFit="contain"
              />
            </div>
          </div>

          <div>
            <H2>IHRE VORTEILE AUF EINEN BLICK</H2>
            <Text>
              <b>✓ Kunststoff statt Aluminium oder Stahl</b>
              <ul>
                <li>Erheblich geringeres Gewicht bei gleicher Robustheit</li>
                <li>Günstiger im Einkauf</li>
                <li>Fertigung in kürzerer Zeit</li>
                <li>Energieeffizienter</li>
              </ul>
              <b>✓ Ein System – so Vielfältig wie Ihre Anforderungen</b>
              <ul>
                <li>Kombination verschiedenster Teile für eine Vielfalt an verschiedenen Anwendungen</li>
                <li>Wenige Grundteile eröffnen Ihnen bereits ein enorm breites Anwendungsspektrum</li>
              </ul>
              <b>
                ✓ Bequem handhabbar und schnell installiert
              </b>
              <ul>
                <li>
                Signifikant kürzere Umrüstzeit (z.B. Halbierung bei {' '}
                  <a
                    href="https://www.maschinenmarkt.vogel.de/saugsystem-aus-kunststoff-halbiert-umruestzeiten-a-285311/?p=2"
                    rel="noreferrer"
                    target="_blank"
                  >
                    Elring Klinger
                  </a>
                  )
                </li>
                <li>Simple Einstellung der Saugknöpfe per Hand via Raster-Verdrehsicherung</li>
                <li>Lediglich drei Werkzeuge werden für die Montage benötigt</li>
              </ul>
            </Text>
          </div>
        </TwoColumnGrid>

        <H2 style={{ marginTop: space[20] }}>Bewährt in langjähriger Praxis</H2>
        <Text style={{ marginBottom: space[20] }}>
          Das Herga Leichtbau-Saugsystem ist aus der Praxis entstanden und hat
          sich in dieser auch vielfach bewährt. Mehr als 1000 Einzelgreifer
          wurden bereits von uns angefertig. Entwickler ist das
          Konstruktionsbüro Herga, das seit 1991 auf den Werkzeug- und
          Maschinenbau spezialisiert ist. Eingesetzt wird das
          Leichtbau-Saugsystem zum Beispiel im Maschinen- und Formenbau, im
          Roboter- und Transferhandling sowie bei Verpackungsmaschinen- und
          Werkzeugherstellern.
          <br />
          <br /> Sie können sofort von den einzigartigen Vorteilen des Herga
          Leichtbau-Saugsystems profitieren und den Transport Ihrer Teile
          vereinfachen. Nehmen Sie gleich Kontakt mit uns auf.
          <br />
          <br />
          <Button backgroundColor={colors.hergaBlue} style={{ color: 'white' }}>
            <a href="/downloads/Herga_Katalog.pdf" target="_blank">
              Katalog herunterladen
            </a>
          </Button>
        </Text>
      </Container>
    </Page>
  )
}

export default LeichtbauPage
